import { BASE_URL, UPLOAD_BASE_URL } from './configs/axiosConfig';
export const GET_VENDORS = '/admin/vendors-v2';
export const DELETE_USER = '/users';
export const DOWNLOAD_PDF = '/registrations/pdf';
export const GET_VENDORS_DETAILS = '/admin/vendorDetails';
export const GET_VENDORS_Reg_DETAILS = '/users';
export const UPDATE_VENDORS_DETAILS = '/registration';
export const GET_SOCIAL_LINKS = '/users/sociallinks';
export const PRODUCT_IMAGE_BASE_URL = `${UPLOAD_BASE_URL}/uploads/products/products_images/`;
export const POST_WIDGET = `${BASE_URL}/widgets/single`;
export const BUSINESS_POST = '/business';
export const PUT_WIDGET = `${BASE_URL}/widgetDetails/`;
export const PUT_WIDGET_DETAILS = `${BASE_URL}/widgetDetails/`;
export const POST_WIDGET_DETAILS = `${BASE_URL}/widgetDetails`;
export const ALL_WIDGET = `${BASE_URL}/widgets`;
export const GET_WIDGET_DETAILS = `${BASE_URL}/widgetDetails/search/widgetId/`;
export const IMAGE_BASE_URL = `${UPLOAD_BASE_URL}/uploads/widget/widget_images/`;
// /api/admin/vendorDetails/
export const GET_ALL_WEB_COMPONENT = '/webcomponents';
export const PUT_SINGLE_WIDGET = '/widgets/';
export const SEARCH_VENDOR = '/admin/vendors/search/';
export const GET_SUBSCRIPTION_DETAILS = 'admin/create-offline-subscription';
export const GET_CATALOG_PRODUCTS = '/users/products/';
