export const GET_B2B_DATA = 'GET_B2B_DATA';
export const GET_B2B_USER = 'GET_B2B_USER';
export const SET_B2B_TOTAL_DATA = 'SET_B2B_TOTAL_DATA';
export const SET_B2B_CURRENT_PAGE = 'SET_B2B_CURRENT_PAGE';
export const SET_SEARCHED_B2B_DATA = 'SET_SEARCHED_B2B_DATA';
export const GET_SUBSCRIPTION_DETAILS = 'GET_SUBSCRIPTION_DETAILS';
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const RESET_MY_CATALOG_DATA = 'RESET_MY_CATALOG_DATA';
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR';
export const INITIAL_STATE = 'INITIAL_STATE';
