import React, { useEffect } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Typography,
} from '@mui/material';
// import { Search } from "@mui/icons-material";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { ReactComponent as ProductImage1 } from '../../assets/product1.svg';
import { ReactComponent as InfoBtn } from '../../assets/info-btn.svg';
import Popover from '@mui/material/Popover';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { ReactComponent as DownloadIcon } from '../../assets/download-icon.svg';
import {
  getVendorDetails,
  updateVendorStatus,
} from '../B2BProfile/redux/actions/b2b-action';
import {
  extractDocNameAndNumber,
  getCategoryFromTitles,
} from '../B2BProfile/B2BProfileView';
import { useHistory } from 'react-router';
import B2BRejectModal from '../B2BProfile/B2BRejectModal';
import { PRODUCT_IMAGE_BASE_URL } from '../../apis/endpoints';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  BANNER_BASE_URL,
  RESUME_BASE_URL,
  SOCIAL_MEDIA_IMAGE_PATH,
} from '../../apis/configs/axiosConfig';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import { decryptParams } from '../../utils/helperFunctions';

function FreelancerProfileViewReason({
  b2bUser,
  updateVendorStatus,
  regId,
  status,
  rejected_reason,
  getVendorDetails,
}) {
  const [reason, setReason] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [popperText, setPopperText] = React.useState('');
  const [popper, setPopper] = React.useState(null);
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const { id: reg_id } = useParams();
  console.log('reg_id', reg_id);

  // const { userId } = location.state;
  // const currentB2bUser = location.state.currentB2bUser;
  // console.log("=====B2BProfileView props", currentB2bUser);
  //name,user.mobile_number,user.mobile,company_name,about_company,products[0].title,business_details-contain array
  const {
    name,
    user,
    company_name,
    about_company,
    business_details,
    whatsapp_number,
    additional_detail1,
    additional_detail2,
    additional_detail3,
    address1,
    address2,
    pincode,
    socialLinks,
    city,
    state,
    registration_type,
    products,
    categories,
    education,
    language,
    dob,
    certificates,
    freelancer_resumes,
    freelancer_role,
    freelancer_bio,
    freelancer_banner_projects,
    hourly_rate,
  } = b2bUser;

  useEffect(() => {
    getVendorDetails(reg_id);
  }, []);

  console.log('===FreelancerProfileViewReason user', b2bUser, categories);
  let mobile_number,
    email,
    categoriList,
    certificateList,
    resumes,
    projectNames;
  if (user) {
    const { mobile_number: userMobileNumber, email: userEmail } = user;
    mobile_number = userMobileNumber;
    email = userEmail;
  } else {
    mobile_number = '';
    email = '';
  }
  if (categories) {
    categoriList = getCategoryFromTitles(categories);
  } else {
    categoriList = '';
  }
  if (certificates) {
    certificateList = certificates.map((item) => item.name).join(', ');
  } else {
    certificateList = '';
  }
  if (freelancer_resumes) {
    console.log('freelancer_resumes', freelancer_resumes);

    resumes = freelancer_resumes
      .map((item) => item.freelancer_resume)
      .join(', ');
  } else {
    resumes = '';
  }
  if (freelancer_banner_projects) {
    projectNames = freelancer_banner_projects
      .map((item) => item.banner_name)
      .join('\n');
  } else {
    projectNames = '';
  }

  const productType = registration_type === 3 ? 'Service' : 'Product';
  console.log('===B2BProfileView products', b2bUser, products);
  const businessDetails = extractDocNameAndNumber(business_details);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleClick = (event, row) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function updateVendorStatusDetails(status) {
    updateVendorStatus(regId, { status });
  }
  function handleRejectSave() {
    console.log('====handleRejectSave', reason);
    updateVendorStatus(regId, { status: 'rejected', rejected_reason: reason });
    setOpenModal(false);
    history.goBack();
  }
  const handleInfIconClick = (event) => {
    // setAnchorEl(rejected_reason);
    setAnchorEl(event.currentTarget);
  };

  const handleInfIconClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  function toCamelCase(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toUpperCase() : word.toLowerCase();
      })
      .replace(/\s+/g, '');
  }

  const openPopper = Boolean(popper);
  const idd = openPopper ? 'simple-popover' : undefined;

  const handleClickPopper = (event, about_company) => {
    setPopper(event.currentTarget);
    setPopperText(about_company);
  };
  const handleClosePopper = () => {
    setPopper(null);
    setPopperText('');
  };

  function truncateText(text = '', length = 50) {
    if (text?.length <= length) {
      return text || '';
    } else if (text?.length == undefined) {
      return text;
    }

    return text?.substr(0, length) + '\u2026' || '';
  }

  return (
    <SideBarContent className="side-test">
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Box className="d-flex justify-content-between mb-4 align-items-center">
            {status && (
              <div className="ribbon mb-5">
                <a className={'' + status} href="#">
                  {toCamelCase(status)}
                </a>
                {/* <a className="approved" href="#">{toCamelCase(status)}</a> */}
              </div>
            )}
            <Box className="d-flex justify-content-between mb-4 align-items-center p-2">
              {status === 'pending' && (
                <Box className="d-flex justify-content-end mb-4">
                  <Button
                    className="btn-approve me-3"
                    onClick={() => {
                      updateVendorStatusDetails('approved');
                      history.goBack();
                    }}
                  >
                    Approve
                  </Button>
                  {/* <Button className="btn-hold me-3">Hold</Button> */}
                  <Button className="btn-reject" onClick={handleOpenModal}>
                    Reject
                  </Button>
                  <B2BRejectModal
                    handleSave={handleRejectSave}
                    open={openModal}
                    reason={reason}
                    setReason={setReason}
                    onClose={handleCloseModal}
                  />
                </Box>
              )}
              {status === 'rejected' && (
                <Box className="d-flex justify-content-end mb-4">
                  <Button
                    className="btn-approve me-3"
                    onClick={() => {
                      updateVendorStatusDetails('approved');
                      history.goBack();
                    }}
                  >
                    Approve
                  </Button>

                  <Button
                    className="btn-info"
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleInfIconClick}
                  >
                    <InfoBtn />
                  </Button>
                </Box>
              )}
              {status === 'approved' && (
                <Box className="d-flex justify-content-end mb-4">
                  <Button className="btn-reject" onClick={handleOpenModal}>
                    Reject
                  </Button>
                  <B2BRejectModal
                    handleSave={handleRejectSave}
                    open={openModal}
                    reason={reason}
                    setReason={setReason}
                    onClose={handleCloseModal}
                  />
                </Box>
              )}
            </Box>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleInfIconClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Typography sx={{ p: 2 }}>
                {rejected_reason ? rejected_reason : 'No reason given'}.
              </Typography>
            </Popover>
          </Box>
          <Grid
            container
            rowSpacing={1.5}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            className="align-items-start justify-content-between mb-4"
          >
            <Grid item xs={10} md={5} className="" rowSpacing={4}>
              <Typography className="fs-20 fw-bold text-black font-skmodernist">
                Personal Details
              </Typography>
              <Divider className="main-divider my-3"></Divider>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Name
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Mobile No.
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {mobile_number}
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Email
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {email}
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Freelancer Category
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {categoriList}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                className="d-flex justify-content-between align-items-end mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
                  WhatsApp number
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {whatsapp_number}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                className="d-flex justify-content-between align-items-end mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
                  Key details about your business
                </InputLabel>
                <Grid
                  item
                  md={4}
                  className="d-flex justify-content-between align-items-start mb-2"
                >
                  <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                    {additional_detail1}
                  </Typography>
                  <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                    {additional_detail2}
                  </Typography>
                  <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                    {additional_detail3}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                md={4}
                className="d-flex justify-content-between align-items-end mb-2"
              >
                <InputLabel className="text-start w-70 fs-15 text-black font-skmodernist">
                  Company Address
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {address1}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                className="d-flex justify-content-between align-items-end mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
                  City/District
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {city}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                className="d-flex justify-content-between align-items-end mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
                  State
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {state}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                className="d-flex justify-content-between align-items-start mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
                  Social Links
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 font-skmodernist">
                  <Grid container spacing={6}>
                    {socialLinks &&
                      socialLinks.map((s) => (
                        <Grid
                          item
                          md={4}
                          key={s.id}
                          className="d-flex justify-content-between align-items-start mb-2"
                        >
                          <Typography className="text-grey  fs-15 font-skmodernist d-flex align-items-start justify-content-center">
                            <a
                              href={s.social_url}
                              key={s.id}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => window.open(s.social_url, '')}
                            >
                              <img
                                src={`${SOCIAL_MEDIA_IMAGE_PATH}/${s.social_media_master.media_image_path}`}
                                alt={s.social_name}
                                style={{ width: '38px', height: '38px' }}
                              />
                            </a>
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Date of Birth
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {dob && moment(dob).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Education
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {education}
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Language
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {language}
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Certification
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-secondary font-skmodernist">
                  {certificateList}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={10} md={5} className="" rowSpacing={4}>
              <Typography className="fs-20 fw-bold text-black font-skmodernist">
                Professional Details
              </Typography>
              <Divider className="main-divider my-3"></Divider>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Resume
                </InputLabel>
                {resumes ? (
                  <Typography className="text-grey w-50 fs-15 text-secondary font-skmodernist">
                    {/* {resumes} */}
                    <IconButton
                      className="fs-15 ps-0"
                      onClick={() => {
                        saveAs(RESUME_BASE_URL + resumes, resumes);
                      }}
                    >
                      {name ? name + '_Resume' : 'Resume'}
                    </IconButton>
                    <IconButton
                      className="fs-12"
                      onClick={() => {
                        saveAs(RESUME_BASE_URL + resumes, resumes);
                      }}
                    >
                      <DownloadIcon height={20} width={20} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography className="text-grey w-50 fs-15 text-secondary font-skmodernist">
                    Resume not uploaded.{' '}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Your Professional Role
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {freelancer_role}
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Bio
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {/* {freelancer_bio} */}
                  <span style={{ width: '175px', display: 'inline-block' }}>
                    {truncateText(freelancer_bio)}{' '}
                  </span>
                  {freelancer_bio?.length > 50 ? (
                    <Button
                      aria-describedby={idd}
                      className="ps-2 justify-content-start btn-tooltip"
                      onClick={(e) => handleClickPopper(e, freelancer_bio)}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          width: '19px',
                          height: '19px',
                          color: '#864FFD',
                        }}
                      />
                    </Button>
                  ) : (
                    <></>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Projects
                </InputLabel>
                <Typography
                  className="text-grey w-50 fs-15 text-grey font-skmodernist"
                  sx={{ overflowWrap: 'break-word' }}
                >
                  {/* {projectNames} */}
                  {freelancer_banner_projects?.length > 0 &&
                    freelancer_banner_projects.map((item) => {
                      // item.banner_name
                      return (
                        <IconButton
                          className="fs-15  ps-0"
                          onClick={() => {
                            saveAs(
                              BANNER_BASE_URL + item?.banner_image,
                              item?.banner_name
                            );
                          }}
                        >
                          {item?.banner_name ?? ''}
                        </IconButton>
                      );
                    })}
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={12}
                className="d-flex justify-content-between align-items-start mb-2 mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">
                  Hourly Base Rate
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {hourly_rate}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Typography className="fs-20 fw-bold text-black font-skmodernist">
            {productType} Details
          </Typography>
          <Divider className="main-divider my-3"></Divider>

          <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: '450px' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>{productType} Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products &&
                    products.map((row) => (
                      <TableRow>
                        <TableCell>
                          <div className="d-flex justify-content-start align-items-center">
                            <div className="w-50 d-flex">
                              <div className="me-3">
                                <img
                                  src={`${PRODUCT_IMAGE_BASE_URL}${row.default_image}`}
                                  alt="product-image"
                                  width="80"
                                  height="80"
                                  style={{
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                  }}
                                  className="product-image"
                                />
                              </div>
                              <div className="">
                                <Typography
                                  variant="h5"
                                  className="fs-16 text-black mb-2 fw-normal font-skmodernist"
                                  component="div"
                                >
                                  {row.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className="fs-14 text-grey mb-2 font-gotham"
                                >
                                  {row.description}.
                                </Typography>
                              </div>
                            </div>

                            <div className="w-50">
                              <Typography
                                variant="h5"
                                className="fs-13 text-darkgrey mb-0 font-skmodernist"
                              >
                                Starting at
                              </Typography>

                              <Typography
                                variant="h5"
                                className="fs-15 text-secondary fw-bold mb-2 font-skmodernist"
                              >
                                ₹ {row.budget}/
                                <span className="fs-13 text-grey fw-light">
                                  Piece
                                </span>
                              </Typography>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <CustomPagination page={page} count={data.length} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} /> */}
          </Paper>
        </Box>
      </Box>
      <Popover
        id={idd}
        open={openPopper}
        anchorEl={popper}
        onClose={handleClosePopper}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 2, maxWidth: '300px' }}>
          <Typography
            className="fs-12 fw-normal font-gotham text-black"
            sx={{ wordBreak: 'break-all' }}
          >
            {popperText}
          </Typography>
        </Box>
      </Popover>
    </SideBarContent>
  );
}
const mapStateToProps = ({ b2bUser, signin }) => {
  console.log('====mapStateToProps b2bUser 567', b2bUser.b2bUser);

  return {
    b2bUser: b2bUser.b2bUser,
    b2bloading: b2bUser.loading,
    regId: b2bUser.b2bUser.id,
    status: b2bUser.b2bUser.status,
    rejected_reason: b2bUser.b2bUser.rejected_reason,
  };
};

export default connect(mapStateToProps, {
  updateVendorStatus,
  getVendorDetails,
})(FreelancerProfileViewReason);
