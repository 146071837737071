import React, { useMemo } from 'react';
import { Pagination, Stack, Typography } from '@mui/material';

const CustomPagination = ({ page, count, rowsPerPage, onPageChange }) => {
  const numPages = useMemo(
    () => Math.ceil(count / rowsPerPage),
    [count, rowsPerPage]
  );

  const handlePageChange = (event, value) => {
    onPageChange(value); // Directly pass the value, so page numbers start from 1
  };

  // Calculate the starting and ending indices
  const startIndex = useMemo(
    () =>
      page === 1 || count < rowsPerPage ? 1 : (page - 1) * rowsPerPage + 1,
    [page, count, rowsPerPage]
  );

  const endIndex = useMemo(
    () => Math.min(page * rowsPerPage, count),
    [page, rowsPerPage, count]
  );

  // Ensure startIndex never exceeds endIndex
  const validStartIndex = Math.min(startIndex, endIndex);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        borderTop: '1px solid #E4E4E4',
      }}
    >
      <Typography
        variant="body1"
        className="fs-15 font-skmodernist"
        sx={{ color: '#5F6165' }}
      >
        {count === 0
          ? 'Showing 0 Results'
          : `Showing ${validStartIndex}-${endIndex} of ${count} Results`}
      </Typography>
      <Stack spacing={2} direction="row">
        <Pagination
          count={numPages}
          page={page}
          onChange={handlePageChange}
          shape="rounded"
          variant="outlined"
          sx={{ button: { minWidth: '32px' } }}
        />
      </Stack>
    </div>
  );
};

export default CustomPagination;
