import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import { ReactComponent as EditIcon } from '../../assets/cat-edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/cat-delete-icon.svg';
import {
  clearSocialMediaObj,
  createSocialMedia,
  fetchSocialMedia,
  setsocialMediaObj,
  updateSocialMediaReq,
} from './redux/actions/social-media-action';
import { connect } from 'react-redux';
import { SOCIAL_MEDIA_URL } from '../../apis/configs/axiosConfig';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import ServerModal from '../../component/Modal';
import Progress from '../../component/CommonLoading';
import useValidation from '../../hooks/useValidation';
import { hasAnyErrorPresent } from '../../utils/validation';
import { compressImages } from '../../utils/constants';

const CustomModal = ({
  open,
  onClose,
  socialModal,
  createSocialMedia,
  fetchSocialMedia,
  action_Type,
  socialMediaObj,
  setsocialMediaObj,
  updateSocialMediaReq,
  clearSocialMediaObj,
  all_links,
  pushMessage,
  loading,
}) => {
  const imageUploadRef = useRef(null);

  const [alreadyExist, setAlreadyExist] = useState(false);

  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [dialogType, setDialogType] = useState('');

  const { validateInput, errors } = useValidation();

  const handleSave = async () => {
    try {
      if (alreadyExist) {
        pushMessage('Name already Exist');
        return;
      }

      if (action_Type === 1) {
        if (socialMediaObj?.media_name === '') {
          validateInput('social_name', socialMediaObj?.media_name);
        }
        if (socialMediaObj?.media_image_path == null) {
          validateInput('social_image', socialMediaObj?.media_image_path?.size);
        }

        if (!hasAnyErrorPresent(errors)) {
          let formData = new FormData();
          formData.append('media_name', socialMediaObj?.media_name);
          formData.append('is_active', socialMediaObj?.is_active);
          formData.append('image', socialMediaObj?.media_image_path);
          await createSocialMedia(
            formData,
            () => {
              fetchSocialMedia();
              onClose();
              clearSocialMediaObj();
              pushMessage('Social Media created successfully.');
            },
            (error) => {
              pushMessage(error.error);
            }
          );
        }
      } else {
        if (socialMediaObj?.media_name === '') {
          validateInput('social_name', socialMediaObj?.media_name);
        }
        if (socialMediaObj?.media_image_path == null) {
          validateInput('social_image', socialMediaObj?.media_image_path?.size);
        }
        if (!hasAnyErrorPresent(errors)) {
          let formData = new FormData();
          formData.append('media_name', socialMediaObj?.media_name);
          formData.append('is_active', socialMediaObj?.is_active);
          if (typeof socialMediaObj.media_image_path !== 'string') {
            formData.append('image', socialMediaObj?.media_image_path);
          }
          await updateSocialMediaReq(
            socialMediaObj.id,
            formData,
            () => {
              fetchSocialMedia();
              onClose();
              clearSocialMediaObj();
              pushMessage('Social Media Updated successfully');
            },
            (error) => {
              pushMessage(error);
            }
          );
        }
      }

      // Handle save logic here
    } catch (error) {
      console.log(error);
    }
  };

  const modelYesCallback = () => {
    setsocialMediaObj({ ...socialMediaObj, media_image_path: null });
    setOpenUpdateModal(false);
  };

  const handleClearState = () => {
    if (action_Type === 0) {
      setDialogType('remove');
      setOpenUpdateModal(true);
    } else {
      clearSocialMediaObj();
      // setsocialMediaObj(null);
    }
  };

  const handleDuplicate = (e) => {
    let obj = { ...socialMediaObj };
    obj.media_name = e.target.value;
    validateInput('social_name', obj.media_name);
    setsocialMediaObj(obj);
  };

  useEffect(() => {
    if (action_Type === 1) {
      for (let i = 0; i < all_links.length; i++) {
        if (
          all_links[i]?.media_name?.toLowerCase() ===
          socialMediaObj?.media_name?.toLowerCase()
        ) {
          setAlreadyExist(true);
          return;
          // `${element.media_name} is already Exists`
        } else {
          setAlreadyExist(false);
        }
      }
    }
  }, [all_links, socialMediaObj?.media_name]);

  const handleCancel = () => {
    onClose(true); // Close the modal
    clearSocialMediaObj();
  };

  useEffect(() => {
    return () => {
      clearSocialMediaObj();
    };
  }, []);

  const handleMediaImageUpload = async (e) => {
    let obj = { ...socialMediaObj };
    let files = e.target.files;

    const allowedTypes = ['image/jpeg', 'image/png'];

    for (let file of files) {
      if (!allowedTypes.includes(file.type)) {
        pushMessage(
          'Wrong File type selected. Please upload a valid image (JPG, JPEG or PNG ).'
        );
        return;
      }
    }

    let compressFiles = await compressImages(files);
    console.log('compressImages:', compressFiles);
    obj.media_image_path = compressFiles[0];
    console.log('media types of data: ', typeof obj.media_image_path);
    validateInput('social_image', compressFiles[0].size);
    setsocialMediaObj(obj);
    if (action_Type === 0) {
      setOpenUpdateModal(false);
    }
  };

  const handeImageRef = () => {
    console.log('handleimagREFF', action_Type);
    // imageUploadRef.current.click();
    if (action_Type === 0) {
      setDialogType('replace');
      setOpenUpdateModal(true);
      // imageUploadRef.current.click();
    } else {
      console.log('else');

      imageUploadRef.current.click();
    }
  };

  useEffect(() => {}, [socialMediaObj?.media_name]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle className="custom-border-header">
          Create SocialMediaMaster
        </DialogTitle>
        <DialogContent>
          <div className="col-md-12 mb-2">
            <InputLabel
              className="text-start fs-14 text-black font-skmodernist"
              htmlFor="inputPlanName"
            >
              Social Media Name <span className="text-mandatory">*</span>
            </InputLabel>
            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
              <OutlinedInput
                id="inputPlanName"
                type="text"
                inputProps={{
                  maxLength: 100,
                }}
                disabled={action_Type === 0}
                value={socialMediaObj?.media_name}
                onChange={handleDuplicate}
                placeholder="Enter social media name"
                required
                autoFocus
                fullWidth
                margin="none"
              />
            </FormControl>
            <FormHelperText sx={{ color: 'red' }} id="social-helper-text">
              {errors.social_name}
            </FormHelperText>
          </div>
          {alreadyExist && <div>{'Already Exists'}</div>}
          {openUpdateModal && (
            <ServerModal
              open={openUpdateModal}
              saveImg={handleSave}
              openFile={handeImageRef}
              fileRef={imageUploadRef.current}
              close={setOpenUpdateModal}
              dialogtype={dialogType}
              yesCallBack={modelYesCallback}
            />
          )}
          <div className="mb-2">
            <InputLabel
              htmlFor="inputCIN"
              className="text-start fs-14 text-black font-skmodernist"
            >
              Upload Image <span className="text-mandatory">*</span>
            </InputLabel>
            <span className="text-muted fs-12">(JPEG or PNG files only)</span>{' '}
            <input
              type="file"
              style={{ display: 'none' }}
              ref={imageUploadRef}
              onChange={handleMediaImageUpload}
              id="file"
              accept=".jpg,.jpeg,.png"
            />
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              className="add-button mt-2 fs-15 font-skmodernist fw-bold"
              sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
              onClick={handeImageRef}
            >
              <img src={AddBtnIcon} alt="add-btn-icon" className="user-logo" />
            </Button>
            <Typography class="text-muted fs-12">
              (JPG, JPEG and PNG files only, maximum size: 2 MB)
            </Typography>
            <FormHelperText sx={{ color: 'red' }} id="social-helper-text">
              {errors.social_image}
            </FormHelperText>
          </div>

          <div className="d-flex justify-content-between align-items-center my-3">
            <div className="d-flex align-items-center">
              {/* <FacebookIcon /> */}
              {socialMediaObj?.media_image_path && (
                <img
                  src={
                    socialMediaObj?.media_image_path instanceof Blob ||
                    socialMediaObj?.media_image_path instanceof File
                      ? URL.createObjectURL(socialMediaObj?.media_image_path)
                      : SOCIAL_MEDIA_URL + socialMediaObj?.media_image_path
                  }
                  height="50px"
                  width="50px"
                  alt={socialMediaObj?.media_image_path}
                />
              )}
              <Typography
                variant="body1"
                align="left"
                className="fs-14 text-darkgrey fw-normal mb-0 ms-3 font-skmodernist"
                gutterBottom
              >
                {socialMediaObj?.media_name}
              </Typography>
            </div>
            <div className="d-flex">
              {/* <IconButton className="cat-edit-icon " >
              <EditIcon />
            </IconButton> */}
              {(socialMediaObj?.media_name ||
                socialMediaObj?.media_image_path) && (
                <IconButton
                  className="cat-delete-icon"
                  onClick={handleClearState}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-4 custom-control custom-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={socialMediaObj?.is_active}
                    onChange={(e) => {
                      let obj = { ...socialMediaObj };
                      obj.is_active = e.target.checked;
                      setsocialMediaObj(obj);
                    }}
                    color="primary"
                  />
                }
                label="Active"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} className="btn-cancel me-2">
            Cancel
          </Button>
          <Button onClick={handleSave} className="btn-save">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  const { action_Type, socialMediaObj, all_links, loading } = state.socialmedia;
  return {
    action_Type,
    socialMediaObj,
    all_links,
    loading,
  };
};

export default connect(mapStateToProps, {
  createSocialMedia,
  fetchSocialMedia,
  setsocialMediaObj,
  updateSocialMediaReq,
  clearSocialMediaObj,
  pushMessage,
})(CustomModal);
