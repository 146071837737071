export const GET_USER_DATA = 'GET_USER_DATA';
export const POST_USER_DATA = 'POST_USER_DATA';
export const SET_USER_ACTION_TYPE = 'SET_USER_ACTION_TYPE';
export const ALL_USER_FILTER = 'ALL_USER_FILTER';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const UPDATE_ROLE_DATA = 'UPDATE_ROLE_DATA';
export const USER_MOBILE_NO = 'USER_MOBILE_NO';
export const USER_COUNTRY_CODE = 'USER_COUNTRY_CODE';
export const USER_COUNTRY_CODE_NAME = 'USER_COUNTRY_CODE_NAME';
export const GET_TOTAL_USER_DATA_COUNT = 'GET_TOTAL_USER_DATA_COUNT';
export const GET_TOTAL_USER_CURRENT_PAGE = 'GET_TOTAL_USER_CURRENT_PAGE';
