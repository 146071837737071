import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import useValidation from '../../hooks/useValidation';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  FormHelperText,
  Modal,
  Box,
} from '@mui/material';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import { ReactComponent as HomepageIcon } from '../../assets/homepage-icon.svg';
import { ReactComponent as CategoryImage } from '../../assets/category-image.svg';
import { ReactComponent as EditIcon } from '../../assets/cat-edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/cat-delete-icon.svg';
import {
  createProductCategoryData,
  saveCategory,
  resetCategory,
  setParent,
  setEdited,
  setRank,
  setActive,
  setParentCategory,
  setProductCategory,
  setPlanName,
  setSelectedRowData,
  putProductCategoryData,
  saveEditCategory,
  setFilteredProductCategory,
  setEditRank,
} from './redux/actions/productcategory-action';
import { connect } from 'react-redux';
import { PRODUCT_CATEGORY_URL } from '../../apis/configs/axiosConfig';
import '../../index.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ProductCategoryMasterModal = ({
  data = [],
  open,
  onClose,
  createProductCategoryData,
  saveCategory,
  createProductCategory,
  resetCategory,
  productCategory,
  editProductCategory,
  setParent,
  setEdited,
  setRank,
  setActive,
  setParentCategory,
  setProductCategory,
  setPlanName,
  is_parent,
  is_edited,
  is_rank,
  is_active,
  is_parent_category,
  is_product_category,
  is_plan_name,
  setSelectedRowData,
  selected_row_data,
  putProductCategoryData,
  saveEditCategory,
  status,
  setFilteredProductCategory,
  filtered_data,
  setEditRank,
  used_rank_array,
  used_freelancer_array,
  validateInput,
  setError,
  error,
  errors,
  setErrors,
}) => {
  const homepageRef = useRef(null);
  const categoryRef = useRef(null);

  const [parCategory, setParCategory] = useState(null);
  const [availableRanks, setAvailableRanks] = useState(
    [...Array(data.length).keys()].map((rank) => rank + 1)
  );
  const [openReuploadCategoryImage, setOpenReuploadCategoryImage] =
    useState(false);
  const [openReupload, setOpenReupload] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteCategoryImage, setOpenDeleteCategoryImage] = useState(false);

  useEffect(() => {
    setAvailableRanks([...Array(data.length).keys()].map((rank) => rank + 1));
  }, [data]);
  useEffect(() => {
    if (selected_row_data && Object.keys(selected_row_data).length > 0) {
      if (selected_row_data?.parent_id !== null) {
        setParent(false);
      }
      if (selected_row_data?.parent_id === null) {
        setParent(true);
      }
      setEdited(true);
      setProductCategory(selected_row_data?.category_type);
      setPlanName(selected_row_data?.title);
      if (selected_row_data?.rank !== 0) {
        setRank(true);
      } else {
        setRank(false);
      }
      setActive(selected_row_data?.status);
      let obj = { ...editProductCategory };
      obj.category_type = selected_row_data?.category_type;
      obj.title = selected_row_data?.title;
      obj.image_path = selected_row_data?.image_path;
      obj.banner_image = selected_row_data?.banner_image;
      obj.rank = selected_row_data?.rank;
      obj.parent_id = selected_row_data?.parent_id;
      obj.status = selected_row_data?.status;
      const foundObject = productCategory.find(
        (obj) => obj.id === selected_row_data?.parent_id
      );
      setParCategory(foundObject);
      saveCategory(obj);
      const filtered_obj = productCategory.filter((item) => {
        return item.id === selected_row_data?.parent_id;
      });
      if (filtered_obj.length > 0) {
        setParentCategory(filtered_obj[0].id);
      }
    } else {
      setParent(false);
      setEdited(false);
    }
  }, [selected_row_data]);

  useEffect(() => {
    const filteredItems = productCategory.filter((item) => {
      return (
        item.category_type.toLowerCase() === is_product_category?.toLowerCase()
      );
    });
    setFilteredProductCategory(filteredItems);
  }, [productCategory, is_product_category]);

  const handleCategoryType = (e) => {
    setProductCategory(e.target.value);
    let obj = { ...editProductCategory };
    obj.category_type = e.target.value;
    saveCategory(obj);
  };

  const handleCategoryName = (e) => {
    validateInput('category_name', e.target.value);
    setPlanName(e.target.value);
    let obj = { ...editProductCategory };
    obj.title = e.target.value;
    saveCategory(obj);
  };

  const handleHomepageIcon = (e) => {
    validateInput(
      'homepage_icon',
      e.target.files[0] ? [e.target.files[0]] : []
    );
    if (selected_row_data) {
      let obj = { ...editProductCategory };
      obj.image_path = e.target.files[0];
      saveEditCategory(obj);
      e.target.value = null;
    } else {
      let obj = { ...editProductCategory };
      obj.image_path = e.target.files[0];
      saveCategory(obj);
      e.target.value = null;
    }
  };

  const handleDeletHomepageIcon = () => {
    if (selected_row_data) {
      let obj = { ...editProductCategory };
      obj.image_path = null;
      saveEditCategory(obj);
    } else {
      let obj = { ...editProductCategory };
      obj.image_path = null;
      saveCategory(obj);
    }
  };

  const handleCategoryImage = (e) => {
    validateInput(
      'category_image',
      e.target.files[0] ? [e.target.files[0]] : []
    );
    if (selected_row_data) {
      let obj = { ...editProductCategory };
      obj.banner_image = e.target.files[0];
      saveEditCategory(obj);
      e.target.value = null;
    } else {
      let obj = { ...editProductCategory };
      obj.banner_image = e.target.files[0];
      saveCategory(obj);
      e.target.value = null;
    }
  };

  const handleDeleteCategoryImage = () => {
    if (selected_row_data) {
      let obj = { ...editProductCategory };
      obj.banner_image = null;
      saveEditCategory(obj);
    } else {
      let obj = { ...editProductCategory };
      obj.banner_image = null;
      saveCategory(obj);
    }
  };

  const handleRank = (e) => {
    if (!is_product_category) {
      alert('Please first select Category Type.');
      return;
    }
    const selectedRank = e.target.value;
    if (selected_row_data) {
      let obj = { ...editProductCategory };
      obj.rank = selectedRank;
      saveEditCategory(obj);
    } else {
      let obj = { ...editProductCategory };
      obj.rank = selectedRank;
      saveCategory(obj);
    }
    validateInput('set_ranking', e.target.value);
  };

  const [openRank, setOpenRank] = useState(false);
  const handleOpenRank = () => setOpenRank(true);
  const handleCloseRank = () => setOpenRank(false);

  const handleRankNew = (e) => {
    console.log(e.target.checked);
    setRank(e.target.checked);
    if (!e.target.checked && editProductCategory.rank) {
      handleOpenRank();
    }
  };

  const handleStatus = (e) => {
    if (selected_row_data) {
      setActive(e.target.checked);
      let obj = { ...editProductCategory };
      obj.status = e.target.checked;
      obj.is_active = e.target.checked ? 'Active' : 'InActive';
      saveEditCategory(obj);
    } else {
      setActive(e.target.checked);
      let obj = { ...editProductCategory };
      obj.status = e.target.checked;
      saveCategory(obj);
    }
  };

  useEffect(() => {
    if (editProductCategory?.title) {
      validateInput('category_name', editProductCategory?.title);
      const foundObject = productCategory.find(
        (obj) =>
          obj.title?.toLowerCase() ===
          editProductCategory?.title.trim()?.toLowerCase()
      );
      if (foundObject) {
        if (editProductCategory?.id !== foundObject?.id) setError(true);
      } else {
        setError(false);
      }
    }
  }, [editProductCategory]);

  const handleParentCategory = (e, newValue) => {
    setParCategory(newValue);
    validateInput('parent_category', newValue?.title);
    setParentCategory(newValue?.id);
    let obj = { ...editProductCategory };
    obj.parent_id = newValue?.id;
    saveCategory(obj);
  };

  const successCallback = () => {
    onClose();
    setPlanName('');
    setProductCategory('');
    setParentCategory('');
    setRank(true);
    setActive(true);
    setParent(false);
    setEdited(false);
    setParCategory(null);
  };
  const errorCallback = () => {
    setEdited(false);
  };
  const handleSave = () => {
    if (is_parent) {
      // console.log('handleSave', is_parent);
      console.log('editProductCategory?.title', editProductCategory?.title);
      validateInput('category_name', editProductCategory?.title);
      validateInput(
        'homepage_icon',
        editProductCategory.image_path ? [editProductCategory.image_path] : []
      );
      validateInput(
        'category_image',
        editProductCategory.banner_image
          ? [editProductCategory.banner_image]
          : []
      );
      if (is_rank) {
        validateInput('set_ranking', editProductCategory?.rank);
        if (!editProductCategory?.rank) {
          return;
        }
      }

      if (!editProductCategory?.title) {
        return;
      }
      if (!editProductCategory?.image_path) {
        return;
      }
      if (!editProductCategory?.banner_image) {
        return;
      }
    } else {
      validateInput('parent_category', is_parent_category);
      if (errors?.parent_category) {
        return;
      }
      if (!is_plan_name) {
        validateInput('category_name', '');
        return;
      }
    }
    let obj = { ...editProductCategory };
    if (selected_row_data) {
      if (!is_rank) {
        if (selected_row_data?.parent_id != null) {
          delete obj.rank;
        } else {
          if (!is_rank) {
            obj.rank = 0;
          }
        }
        saveCategory(obj);
      }
      putProductCategoryData(
        editProductCategory,
        successCallback,
        errorCallback
      );
      setActive(false);
    } else {
      if (!is_rank) {
        obj.rank = 0;
      }
      createProductCategoryData(
        editProductCategory,
        successCallback,
        errorCallback
      );
    }
  };

  const handleCancel = () => {
    onClose(true); // Close the modal
    setSelectedRowData(null);
    if (selected_row_data) {
      setParent(false);
      resetCategory();
      setPlanName('');
      setProductCategory('');
      setParentCategory('');
      setRank(true);
      setActive(true);
      setEdited(false);
    } else {
      setParent(false);
      setPlanName('');
      setProductCategory('');
      setErrors({});
      setParCategory(null);
    }
  };

  useEffect(() => {
    return () => {
      resetCategory();
      setProductCategory('');
      setParentCategory('');
      setPlanName('');
    };
  }, []);

  const handleOpenReupload = () => setOpenReupload(true);
  const handleCloseReupload = () => setOpenReupload(false);

  const handleOpen = () => {
    setOpenDelete(true);
  };
  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleImageRef = () => {
    if (editProductCategory.image_path) {
      handleOpenReupload();
    } else {
      homepageRef.current.click();
    }
  };

  const handleOpenReuploadCategoryImage = () =>
    setOpenReuploadCategoryImage(true);
  const handleCloseReuploadCategoryImage = () =>
    setOpenReuploadCategoryImage(false);

  const handleOpenCategoryImage = () => setOpenDeleteCategoryImage(true);
  const handleCloseCategoryImage = () => setOpenDeleteCategoryImage(false);

  const handleImageRefNew = () => {
    if (editProductCategory.banner_image) {
      handleOpenReuploadCategoryImage();
    } else {
      categoryRef.current.click();
    }
  };

  const handleCheckbox = (e) => {
    setParent(e.target.checked);
    setErrors({});
    // setError(false);
  };

  const handleCheckRank = (rank) => {
    if (editProductCategory?.category_type === 'Business') {
      var rankBsData = used_rank_array?.includes(rank);
      return rankBsData;
    }
    if (
      used_freelancer_array &&
      editProductCategory?.category_type === 'Freelancer'
    ) {
      var rankFlData = used_freelancer_array?.includes(rank);
      return rankFlData;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className="custom-border-header">
        {selected_row_data ? (
          <div>Edit Product Category</div>
        ) : (
          <div>Create Product Category</div>
        )}
      </DialogTitle>
      <DialogContent>
        {/* Modal for Homepage Icon   */}
        <Modal
          open={openDelete}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Elynker
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete the Photo?
            </Typography>
            <div style={{ float: 'right' }}>
              <Button
                onClick={() => {
                  handleClose();
                  handleDeletHomepageIcon();
                }}
              >
                Yes
              </Button>
              <Button onClick={handleClose}>No</Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={openReupload}
          onClose={handleCloseReupload}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Elynker
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to replace the photo?
            </Typography>
            <div style={{ float: 'right' }}>
              <Button
                onClick={() => {
                  handleCloseReupload();
                  homepageRef.current.click();
                }}
              >
                Yes
              </Button>
              <Button onClick={handleCloseReupload}>No</Button>
            </div>
          </Box>
        </Modal>
        {/* Modal for Category Image*/}
        <Modal
          open={openDeleteCategoryImage}
          onClose={handleCloseCategoryImage}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Elynker
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete the Photo?
            </Typography>
            <div style={{ float: 'right' }}>
              <Button
                onClick={() => {
                  handleCloseCategoryImage();
                  handleDeleteCategoryImage();
                }}
              >
                Yes
              </Button>
              <Button onClick={handleCloseCategoryImage}>No</Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={openReuploadCategoryImage}
          onClose={handleCloseReuploadCategoryImage}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Elynker
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to replace the photo?
            </Typography>
            <div style={{ float: 'right' }}>
              <Button
                onClick={() => {
                  handleCloseReuploadCategoryImage();
                  categoryRef.current.click();
                }}
              >
                Yes
              </Button>
              <Button onClick={handleCloseReuploadCategoryImage}>No</Button>
            </div>
          </Box>
        </Modal>
        {/* Modal for Assign Ranking */}
        <Modal
          open={openRank}
          onClose={handleCloseRank}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Elynker
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to remove the ranking?
            </Typography>
            <div style={{ float: 'right' }}>
              <Button
                onClick={() => {
                  setRank(false);
                  setEditRank(0);
                  handleCloseRank();
                  // homepageRef.current.click();
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  setRank(true);
                  handleCloseRank();
                }}
              >
                No
              </Button>
            </div>
          </Box>
        </Modal>
        <div className="row">
          <div className="col-12 custom-control custom-checkbox">
            <FormControlLabel
              control={
                <Checkbox
                  checked={is_parent}
                  onChange={handleCheckbox}
                  color="primary"
                  disabled={is_edited}
                />
              }
              label="Is Parent Category"
            />
          </div>
        </div>

        {is_parent && (
          <div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <InputLabel
                  className="text-start fs-14 text-black font-skmodernist"
                  htmlFor="inputProductCategory"
                >
                  Category Type
                </InputLabel>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Select
                    value={is_product_category}
                    onChange={handleCategoryType}
                    inputProps={{ 'aria-label': 'Without label' }}
                    disabled={is_edited}
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="Business">Business</MenuItem>
                    <MenuItem value="Freelancer">Freelancer</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6 mb-2">
                <InputLabel
                  className="text-start fs-14 text-black font-skmodernist"
                  htmlFor="inputPlanName"
                >
                  Category Name <span className="text-mandatory">*</span>
                </InputLabel>
                <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
                  <OutlinedInput
                    id="inputPlanName"
                    type="text"
                    value={is_plan_name}
                    onChange={handleCategoryName}
                    placeholder="Enter category name"
                    required
                    autoFocus
                    fullWidth
                    margin="none"
                    disabled={is_edited}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </FormControl>
                {error && (
                  <p style={{ color: 'red' }}>Name already exists...</p>
                )}
                <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
                  {errors?.category_name}
                </FormHelperText>
              </div>
            </div>

            <div className="row align-items-end">
              <div className="col-md-6 mb-2">
                <InputLabel
                  htmlFor="inputCIN"
                  className="text-start fs-14 text-black font-skmodernist"
                >
                  Upload Homepage Icon <span className="text-mandatory">*</span>
                </InputLabel>
                <input
                  style={{ display: 'none' }}
                  id="file"
                  ref={homepageRef}
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleHomepageIcon}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  onClick={handleImageRef}
                  className="add-button mt-2 fs-15 font-skmodernist fw-bold"
                  sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                >
                  <img
                    src={AddBtnIcon}
                    alt="add-btn-icon"
                    className="user-logo"
                  />
                </Button>
              </div>

              <div className="col-md-6 d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                  {editProductCategory?.image_path && (
                    <img
                      style={{
                        display: editProductCategory?.image_path
                          ? 'block'
                          : 'none',
                        objectFit: 'contain',
                        marginRight: '10px',
                      }}
                      width={'36px'}
                      height={'36px'}
                      src={
                        editProductCategory?.image_path &&
                        typeof editProductCategory?.image_path === 'string'
                          ? PRODUCT_CATEGORY_URL +
                            editProductCategory?.image_path
                          : URL.createObjectURL(editProductCategory?.image_path)
                      }
                      alt={'homeicon'}
                    />
                  )}
                  <Typography
                    variant="body1"
                    align="left"
                    className="fs-14 text-darkgrey fw-normal mb-0 ms-3 font-skmodernist"
                    gutterBottom
                  >
                    {editProductCategory?.image_path &&
                      (editProductCategory?.image_path instanceof Object
                        ? editProductCategory?.image_path?.name
                        : editProductCategory?.image_path)}
                  </Typography>
                </div>
                <div className="d-flex">
                  <IconButton className="cat-delete-icon">
                    {editProductCategory.image_path && (
                      <DeleteIcon
                        onClick={() => {
                          handleOpen();
                        }}
                      />
                    )}
                  </IconButton>
                </div>
              </div>
              <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
                {errors?.homepage_icon}
              </FormHelperText>
            </div>

            <div className="row align-items-end">
              <div className="col-md-6 mb-2">
                <InputLabel
                  htmlFor="inputCIN"
                  className="text-start fs-14 text-black font-skmodernist"
                >
                  Upload Category Image{' '}
                  <span className="text-mandatory">*</span>
                </InputLabel>
                <input
                  style={{ display: 'none' }}
                  id="file"
                  ref={categoryRef}
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleCategoryImage}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  onClick={handleImageRefNew}
                  className="add-button mt-2 fs-15 font-skmodernist fw-bold"
                  sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                >
                  <img
                    src={AddBtnIcon}
                    alt="add-btn-icon"
                    className="user-logo"
                  />
                </Button>
              </div>

              <div className="col-md-6 d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                  {editProductCategory?.banner_image && (
                    <img
                      style={{
                        display: editProductCategory?.banner_image
                          ? 'block'
                          : 'none',
                        objectFit: 'contain',
                        marginRight: '10px',
                      }}
                      width={'36px'}
                      height={'36px'}
                      src={
                        editProductCategory?.banner_image &&
                        typeof editProductCategory?.banner_image === 'string'
                          ? PRODUCT_CATEGORY_URL +
                            editProductCategory?.banner_image
                          : URL.createObjectURL(
                              editProductCategory?.banner_image
                            )
                      }
                      alt={'homeicon'}
                    />
                  )}
                  <Typography
                    variant="body1"
                    align="left"
                    className="fs-14 text-darkgrey fw-normal mb-0 ms-3 font-skmodernist"
                    gutterBottom
                  >
                    {editProductCategory?.banner_image &&
                      (editProductCategory?.banner_image instanceof Object
                        ? editProductCategory?.banner_image?.name
                        : editProductCategory?.banner_image)}
                  </Typography>
                </div>
                <div className="d-flex">
                  <IconButton className="cat-delete-icon">
                    {editProductCategory.banner_image && (
                      <DeleteIcon onClick={() => handleOpenCategoryImage()} />
                    )}
                  </IconButton>
                </div>
              </div>
              <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
                {errors?.category_image}
              </FormHelperText>
            </div>

            <div className="row">
              <div className="col-4 custom-control custom-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={is_rank}
                      onChange={handleRankNew}
                      color="primary"
                    />
                  }
                  label="Assign Ranking"
                />
              </div>
            </div>

            {is_rank && (
              <div className="col-md-12 mb-2">
                <InputLabel
                  className="text-start fs-14 text-black font-skmodernist"
                  htmlFor="inputProductCategory"
                >
                  Set Ranking{' '}
                  {is_rank ? <span className="text-mandatory">*</span> : null}
                </InputLabel>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Select
                    value={editProductCategory?.rank || '0'}
                    onChange={handleRank}
                    inputProps={{ 'aria-label': 'Without label' }}
                    disabled={!is_rank || !is_product_category}
                    defaultValue="0"
                    // className="ranking-dropdown"
                    className="custom-MuiPopover-root"
                    MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                  >
                    <MenuItem value="0" disabled selected>
                      Select Rank
                    </MenuItem>
                    {availableRanks.map((rank) => (
                      <MenuItem
                        key={rank}
                        value={rank}
                        // disabled={used_rank_array?.includes(rank)}
                        disabled={handleCheckRank(rank)}
                      >
                        {rank}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
                  {errors?.set_ranking}
                </FormHelperText>
              </div>
            )}

            <div className="row">
              <div className="col-4 custom-control custom-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={is_active}
                      onChange={handleStatus}
                      color="primary"
                    />
                  }
                  label="Active"
                />
              </div>
            </div>
          </div>
        )}

        {!is_parent && (
          <div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <InputLabel
                  className="text-start fs-14 text-black font-skmodernist"
                  htmlFor="inputProductCategory"
                >
                  Category Type
                </InputLabel>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Select
                    value={is_product_category}
                    onChange={handleCategoryType}
                    inputProps={{ 'aria-label': 'Without label' }}
                    disabled={is_edited}
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="Business">Business</MenuItem>
                    <MenuItem value="Freelancer">Freelancer</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6 mb-2">
                <InputLabel
                  className="text-start fs-14 text-black font-skmodernist"
                  htmlFor="inputPlanName"
                >
                  Parent Category
                </InputLabel>
                <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
                  <Autocomplete
                    value={parCategory}
                    disabled={is_edited}
                    onChange={handleParentCategory}
                    options={filtered_data}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </FormControl>
                <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
                  {errors?.parent_category}
                </FormHelperText>
              </div>
            </div>

            <div className="col-md-6 mb-2">
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputPlanName"
              >
                Category Name
              </InputLabel>
              <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
                <OutlinedInput
                  id="inputPlanName"
                  type="text"
                  value={is_plan_name}
                  onChange={handleCategoryName}
                  placeholder="Enter category name"
                  required
                  fullWidth
                  margin="none"
                  disabled={is_edited}
                />
              </FormControl>
              {error && <p style={{ color: 'red' }}>Name already exists...</p>}
              <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
                {errors?.category_name}
              </FormHelperText>
            </div>

            {/* <div className="row">
							<div className="col-4 custom-control custom-checkbox">
								<FormControlLabel
									control={
										<Checkbox
											checked={is_active}
											onChange={handleStatus}
											color="primary"
										/>
									}
									label="Active"
								/>
							</div>
						</div> */}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} className="btn-cancel me-2">
          Cancel
        </Button>
        <Button onClick={handleSave} className="btn-save">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStatetoProps = (state) => {
  return {
    createProductCategory: state.productcategory.createProductCategory,
    productCategory: state.productcategory.productCategory,
    editProductCategory: state.productcategory.editProductCategory,
    is_parent: state.productcategory.is_parent,
    is_edited: state.productcategory.is_edited,
    is_rank: state.productcategory.is_rank,
    is_active: state.productcategory.is_active,
    is_parent_category: state.productcategory.is_parent_category,
    is_product_category: state.productcategory.is_product_category,
    is_plan_name: state.productcategory.is_plan_name,
    selected_row_data: state.productcategory.selected_row_data,
    status: state.productcategory.editProductCategory.status,
    filtered_data: state.productcategory.filtered_data,
    used_rank_array: state.productcategory.used_rank_array,
    used_freelancer_array: state.productcategory.used_freelancer_array,
  };
};

export default connect(mapStatetoProps, {
  createProductCategoryData,
  saveCategory,
  resetCategory,
  setParent,
  setEdited,
  setRank,
  setActive,
  setParentCategory,
  setProductCategory,
  setPlanName,
  setSelectedRowData,
  putProductCategoryData,
  saveEditCategory,
  setFilteredProductCategory,
  setEditRank,
})(ProductCategoryMasterModal);
