import { string, array, object, number, ref } from 'yup';
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// const nameRegex = /^[a-zA-Z\s]*$/;
const nameValidation = string().required('Name is required').max(50).min(3);
const companyNameValidation = string()
  .required('Company name is required')
  .max(50)
  .min(3);
const companyAboutValidation = string()
  .required('About company is required')
  .max(50)
  .min(3);
const bannerName = string().required('Banner Name is required');
const widgetColor = string().required('Widget Color is required');
const companCINValidation = string()
  .required('Cin number is required')
  .max(50)
  .min(3);
const companPanValidation = string()
  .required('Pan number is required')
  .max(50)
  .min(3);
const companGstValidation = string()
  .required('Gst number is required')
  .max(50)
  .min(3);
const companGstReasonValidation = string()
  .required('Gst reson is required')
  .max(50)
  .min(3);
const emailValidation = string()
  .matches(emailRegex, 'Invalid email address')
  .required('Email is required')
  .max(200)
  .min(2);
const cityValidation = string().required('City is required').max(50).min(2);
const addressValidation = string().required('address is required');
const mobileNumberValidation = string()
  .required('mobile number is required')
  .min(10, 'Please enter 10 digit number');
// min(10, "Please enter 10 digit only")

const locationValidation = object().required('Location is required');

const productPhotosValidation = array()
  .required('At least one photo is required')
  .min(1, 'At least one photo is required')
  .max(5, 'At most five photo is uploded');
const socialLinkValidation = array()
  .required('At least one social link is required')
  .min(1);
const logoPhotoValidation = array().required('logo image is required');
const coverPhotoValidation = array().required('cover photo is required');
const productNameValidation = string()
  .required('Product name is required')
  .max(50)
  .min(3);
const stateValidation = string().required('state  is required').max(50).min(3);
const subCategoryValidation = string()
  .required('Subcategory is required')
  .min(1, 'At least one subcategory is required');

const productDescriptionValidation = string()
  .required('Product description is required')
  .min(2, 'Product description must be at least 2 characters');

const priceValidation = string()
  .required('Price is required')
  .min(1)
  .max(200, 'Price must be at most 200 characters');

const unitValidation = string()
  .required('Unit is required')
  .max(200, 'Unit must be at most 200 characters');

const orderQuantityValidation = number()
  .required('Order quantity is required')
  .positive('quantity must be positive');

const otpValidation = string()
  .min(6, 'OTP should be 6 digit only')
  .max(6, 'OTP should be 6 digit only')
  .required('OTP required');

const roleValidation = string().required('Role name is required');
const taxNameValidation = string().required('Tax name is required');

const masterNameValidation = string().required('Master Name is required');

const masterValuesArrayValidation = array()
  .required('Atleast Add One Value')
  .min(1, 'Atleast Add One Value');

const moduleValuesValidation = string().required('Values field is required');

const roleValidationCheckbox = string().min(
  1,
  'Please select atleast one Role'
);

const assignRoleUser = string().min(1, 'Please add role to user');

const assignRoleUserArr = array().min(1, 'Please add role to user');

const adminUserValidation = string().required('Only Admin can login');

const categoryNameValidation = string().min(1, 'Category Name is required');

const categoryNameChildValidation = string().required(
  'Category Name is required'
);

const uploadHomepageIcon = array()
  .required('Homepage Icon is required')
  .min(1, 'Image Required')
  .max(1, 'Max 1 image required');

const uploadCategoryImage = array()
  .required('Category Image is required')
  .min(1, 'Image Required')
  .max(1, 'Max 1 image required');

const setRanking = string().required('Assign Rank');

const parentCategory = string().required('Please add Parent Category');
const widgetTemplate = string().required('Select any one template');
const widgetTitle = string().required('Widget title is required');
const widgetSub_title = string().required('Sub title is required');
const widgetButton_text = string().required('Button text is required');
const widgetButton_src = string().required('Please add redirection name');
const widget_bannerSeqNumber = string().required(
  'Please add widget banner sequence number'
);
const widgetImage = string().required('Please add image');
const isActive = string().required('Please add image');

const pageTitleValidation = string().required('Page Title is required');

const pageContentValidation = string().required('Page Content is required');
const widgetNameValidation = string().required('Widget name is required');
const widgetTypeValidation = string().required('Widget type is required');
const rank = string().required('rank type is required');
const plan_name_ = string().required('Plan type is required');
const plan_name_DescriptionValidation = string()
  .min(2, 'Plan description must be at least 2 characters')
  .required('Plan description is required');

const plan_name = object().required('Plan Name is required');
const plan_price = string()
  .min(1, 'Plan price must be at least 2 characters')
  .required('Plan price is required');
const plan_services = number()
  .min(1, 'Plan services must be at least 1')
  .required('Plan services is required');
const socialMediaNameValidation = string().required('Enter Social Media name');

const templateNameValidation = string().required('Template name is required');

const subjectNameValidation = string().required('Subject is required');

const selectTemplateValidation = string().required('Please select from value');

// const socialMediaImageValidation = object().required("Please Upload Image").min(1, "Image Required")
// const socialMediaImageValidation = object().required('Please Upload Image')

// const socialMediaImageSize = number().max(
//     2000000,
//     'File maximum size of 2 MB'
//   );

const socialMediaImageValidation = number()
  .required('Please Upload image')
  .max(2000000, 'File maximum size of 2 MB');

const plan_discount = string()
  .min(1, 'Plan discount must be at least 2 characters')
  .required('Plan discount is required');
const plan_tax = string()
  .min(1, 'Plan tax must be at least 2 characters')
  .required('Plan tax is required');
const plan_total_ammount = string()
  .min(1, 'Plan total ammount must be at least 2 characters')
  .required('Plan total ammount is required')
  .min(2, 'Plan total ammount must be at least 2 characters');
const hasAnyErrorPresent = (obj) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (
        (typeof value === 'string' && value !== '') || // non-empty string
        (Array.isArray(value) && value.length > 0) || // non-empty array
        (value && typeof value === 'object' && Object.keys(value).length > 0) || // non-empty object
        (typeof value === 'number' && value !== 0) // non-zero number
      ) {
        return true;
      }
    }
  }
  return false;
};

export {
  widgetTemplate,
  widgetImage,
  widget_bannerSeqNumber,
  widgetButton_src,
  widgetButton_text,
  widgetSub_title,
  widgetTitle,
  nameValidation,
  emailValidation,
  cityValidation,
  companGstReasonValidation,
  addressValidation,
  companyNameValidation,
  companyAboutValidation,
  companCINValidation,
  plan_name,
  companPanValidation,
  productPhotosValidation,
  productNameValidation,
  subCategoryValidation,
  productDescriptionValidation,
  plan_name_DescriptionValidation,
  plan_name_,
  plan_price,
  plan_services,
  plan_tax,
  plan_discount,
  plan_total_ammount,
  priceValidation,
  unitValidation,
  orderQuantityValidation,
  logoPhotoValidation,
  coverPhotoValidation,
  locationValidation,
  mobileNumberValidation,
  socialLinkValidation,
  stateValidation,
  hasAnyErrorPresent,
  companGstValidation,
  otpValidation,
  roleValidation,
  roleValidationCheckbox,
  masterNameValidation,
  moduleValuesValidation,
  masterValuesArrayValidation,
  assignRoleUser,
  assignRoleUserArr,
  categoryNameValidation,
  uploadHomepageIcon,
  uploadCategoryImage,
  setRanking,
  parentCategory,
  categoryNameChildValidation,
  adminUserValidation,
  pageTitleValidation,
  pageContentValidation,
  socialMediaNameValidation,
  socialMediaImageValidation,
  widgetNameValidation,
  widgetTypeValidation,
  isActive,
  rank,
  bannerName,
  widgetColor,
  taxNameValidation,
  templateNameValidation,
  subjectNameValidation,
  selectTemplateValidation,
};
