import {
  ALL_USER_FILTER,
  CLEAR_USER_DATA,
  GET_USER_DATA,
  GET_TOTAL_USER_DATA_COUNT,
  POST_USER_DATA,
  SET_USER_ACTION_TYPE,
  UPDATE_ROLE_DATA,
  USER_MOBILE_NO,
  USER_COUNTRY_CODE,
  USER_COUNTRY_CODE_NAME,
  GET_TOTAL_USER_CURRENT_PAGE,
} from '../types';

const INITIAL_STATE = {
  all_user: [],
  all_user_count: 0,
  all_user_current_page: 1,
  userMasterObj: {
    email: '',
    name: '',
    city: '',
    country_code: '+91',
    username: '',
    is_active: true,
    roles: [],
    mobile_number: '',
  },
  action_Type: 1, // 1 is for create and 0 is for edit,
  all_user_filter: [],
  country_code_name: 'IN',
  user_mobile_number: '',
  user_country_code: '+91',
};

const userMasterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_DATA:
      return {
        ...state,
        all_user: action.payload,
      };
    case GET_TOTAL_USER_DATA_COUNT:
      return {
        ...state,
        all_user_count: action.payload,
      };
    case GET_TOTAL_USER_CURRENT_PAGE:
      return {
        ...state,
        all_user_current_page: action.payload,
      };
    case POST_USER_DATA:
      return {
        ...state,
        userMasterObj: action.payload,
      };
    case SET_USER_ACTION_TYPE:
      return {
        ...state,
        action_Type: action.payload,
      };
    case ALL_USER_FILTER:
      return {
        ...state,
        all_user_filter: action.payload,
      };
    case CLEAR_USER_DATA:
      return {
        ...state,
        userMasterObj: INITIAL_STATE.userMasterObj,
        country_code_name: INITIAL_STATE.country_code_name,
        user_mobile_number: INITIAL_STATE.user_mobile_number,
        user_country_code: INITIAL_STATE.user_country_code,
      };
    case UPDATE_ROLE_DATA:
      return {
        ...state,
        userMasterObj: {
          ...state.userMasterObj,
          roles: action.payload,
        },
      };
    case USER_MOBILE_NO:
      return {
        ...state,
        user_mobile_number: action.payload,
      };
    case USER_COUNTRY_CODE:
      return {
        ...state,
        user_country_code: action.payload,
      };
    case USER_COUNTRY_CODE_NAME:
      return {
        ...state,
        country_code_name: action.payload,
      };
    default:
      return state;
  }
};

export default userMasterReducer;
