import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import {ReactComponent as ReactIcon} from "../../assets/react.svg"
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo-white.svg';
import { ReactComponent as ElynkerSmallLogo } from '../../assets/elynker-small-logo.svg';
import { ReactComponent as DashboardIcon } from '../../assets/dashboard-icon.svg';
import { ReactComponent as ProductsIcon } from '../../assets/products-icon.svg';
import { ReactComponent as SubscriptionWhite } from '../../assets/subscriptionWhite.svg';
import { ReactComponent as EnquiryIcon } from '../../assets/enquiry-icon.svg';
import { ReactComponent as MastersIcon } from '../../assets/masters-icon-white.svg';
import { ReactComponent as ProfileApprovalIcon } from '../../assets/profile-approval-icon.svg';
import { ReactComponent as ProductCatIcon } from '../../assets/product-category.svg';
import { ReactComponent as RequirementsIcon } from '../../assets/requirements-icon.svg';
import { ReactComponent as ToolsServicesIcon } from '../../assets/tools-services-icon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings-icon.svg';
import { ReactComponent as LogoutIcon } from '../../assets/logout-icon.svg';
import RemoveIcon from '@mui/icons-material/Remove';
// import Routers from "../../navigation/Routers";
import { useHistory } from 'react-router';
import '../../index.css';
import '../../common/fontsize.css';
import { Button, Modal, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// import SiderBarRoutes from '../../navigation/SideBarRoutes';
import { setHeaderTitle } from '../../store/actions/rootActions';
import { connect } from 'react-redux';
import { resetUserData } from '../Otp/redux/actions/otp-action';

const drawerWidth = 225;
const closedDrawerWidth = 65;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  width: closedDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  // width: `calc(${theme.spacing(7)} + 1px)`,
  // [theme.breakpoints.up("sm")]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const navigationConfig = [
  {
    title: 'DashBoard',
    icon: <DashboardIcon />,
    link: '',
  },
  // {
  //   title: 'Products',
  //   icon: <ProductsIcon />,
  //   link: 'product',
  // },
  // {
  //   title: 'Plans',
  //   icon: <PlansIcon />,
  //   link: 'plan',
  // },
  {
    title: 'Enquiry',
    icon: <EnquiryIcon />,
    link: 'enquiry-list',
  },
  {
    title: 'Masters',
    icon: <MastersIcon />,
    link: '*',
    submenus: [
      {
        title: 'Social Media',
        icon: <RemoveIcon />,
        link: 'social-media-master',
      },
      {
        title: 'Roles',
        icon: <RemoveIcon />,
        link: 'role-master',
      },
      {
        title: 'Users',
        icon: <RemoveIcon />,
        link: 'users-master',
      },
      // {
      //   title: 'Product Category',
      //   icon: <RemoveIcon />,
      //   link: 'product-category-master',
      // },
      // {
      //   title: "B2B Profile",
      //   icon: <RemoveIcon />,
      //   link: "b2b-profile"
      // },
      // {
      //   title: "Freelancer Profile",
      //   icon: <RemoveIcon />,
      //   link: "freelancer-profile"
      // },
      {
        title: 'Miscellaneous',
        icon: <RemoveIcon />,
        link: 'masters',
      },
      // {
      //   title: "Edit Miscellaneous",
      //   icon: <RemoveIcon />,
      //   link: "create-masters"
      // },
    ],
  },
  {
    title: 'Profile Approval',
    icon: <ProfileApprovalIcon />,
    link: 'b2b-profile',
  },
  {
    title: 'Product Category',
    // icon: <RemoveIcon />,
    icon: <ProductCatIcon />,
    link: 'product-category-master',
  },
  {
    title: 'Requirements',
    // icon: <RemoveIcon />,
    icon: <RequirementsIcon />,
    link: 'requirements-master',
  },
  {
    title: 'Tools/Services',
    icon: <ToolsServicesIcon />,
    link: 'tools-services-master',
  },
  {
    title: 'Subscription',
    icon: <SubscriptionWhite />,
    link: 'subscription-master',
  },
];

export const otherNavigationConfig = [
  {
    title: 'Settings',
    icon: <SettingsIcon />,
    link: '*',
    submenus: [
      {
        title: 'HomePage-Widget',
        icon: <RemoveIcon />,
        link: 'homepage-widget',
      },
      {
        title: 'Template',
        icon: <RemoveIcon />,
        link: 'template-list',
      },
      {
        title: 'Widget',
        icon: <RemoveIcon />,
        link: 'widget-master',
      },
    ],
  },
];

export const newnavigationconfig = [
  {
    title: 'Create Miscellaneous',
    icon: <DashboardIcon />,
    link: 'create-masters',
  },
  {
    title: 'Edit Miscellaneous',
    icon: <ProductsIcon />,
    link: 'edit-masters',
  },
];

function SideList({ open, setHeaderTitle, resetUserData, userData }) {
  const theme = useTheme();
  const history = useHistory();
  const [menu, setMenu] = React.useState('');
  const [openModel, setOpenModel] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpenModel(false);

  // State to hold the filtered menu items based on user's role permissions
  const [filteredNavigation, setFilteredNavigation] = useState([]);

  const [filterSettingNavigation, setFilterSettingNavigation] = useState([]);
  React.useEffect(() => {
    console.log('====filteredNavigation', filteredNavigation);
  }, [filteredNavigation]);
  // const [currentRoute, setCurrentRoute] = useState('/')
  const token =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NjIsImlhdCI6MTcwNzM3NDYzOCwiZXhwIjoxNzA3NDYxMDM4fQ.Ext6vZP-0-RO_XKxgZdM3kWJaohHV37gm8hVnq9l7T4';

  const handelClick = (text) => {
    sessionStorage.setItem('token', token);
    history.replace(`/${text.link}`);
    setHeaderTitle(text.title);
  };

  useEffect(() => {
    let newFilteredMenu = [];

    navigationConfig.forEach((menuItem) => {
      let obj = {};
      const hasPermission = userData?.roles[0]?.role_permissions?.find((itm) =>
        menuItem.title
          .toLowerCase()
          .includes(itm.system_module.module_name.toLowerCase())
      );
      // console.log("hasPermission :", hasPermission);
      if (hasPermission) {
        obj = { ...menuItem, submenus: null };
        if (menuItem.submenus?.length > 0) {
          let newSubmenus = menuItem.submenus.filter((subMenuItem) => {
            const hasSubmenu = userData?.roles[0]?.role_permissions?.find(
              (itm) =>
                subMenuItem.title
                  .toLowerCase()
                  .includes(itm.system_module.module_name.toLowerCase())
            );
            // console.log("hassubmenuMenu :", hasSubmenu);
            if (hasSubmenu) return true;
            else return false;
          });
          obj.submenus = newSubmenus;
        }
        newFilteredMenu.push(obj);
      }
    });
    setFilteredNavigation(newFilteredMenu);
  }, [userData?.roles]);

  useEffect(() => {
    let newSettingNavigation = [];

    otherNavigationConfig.forEach((menuItem) => {
      let obj = {};
      const hasSettingPermission = userData?.roles[0]?.role_permissions?.find(
        (itm) =>
          menuItem.title
            .toLowerCase()
            .includes(itm.system_module.module_name.toLowerCase())
      );
      if (hasSettingPermission) {
        obj = { ...menuItem, submenus: null };
        if (menuItem.submenus?.length > 0) {
          let newSettingSubmenu = menuItem.submenus.filter((subMenuItem) => {
            const hasSubmenu = userData?.roles[0]?.role_permissions?.find(
              (itm) =>
                subMenuItem.title
                  .toLowerCase()
                  .includes(itm.system_module.module_name.toLowerCase())
            );
            if (hasSubmenu) return true;
            else return false;
          });
          obj.submenus = newSettingSubmenu;
        }
        newSettingNavigation.push(obj);
      }
    });
    console.log('newSettingNavigation:', newSettingNavigation);
    setFilterSettingNavigation(newSettingNavigation);
  }, [userData?.roles]);

  // Define the isOpen function to check if a submenu is open
  const [submenuOpen, setSubmenuOpen] = React.useState([]);

  const isOpen = (index) => {
    return submenuOpen.includes(index);
  };

  // const handleclearState = () => {
  //   resetUserData();
  //   history.replace('/login');
  // };

  // Define the handleClick function to handle clicks on regular menu items
  const handleClick = (menuItem) => {
    // Handle click logic for regular menu items
    console.log('Clicked regular menu item:', menuItem);
    handelClick(menuItem);
    // You can add your logic here, such as navigating to a specific link or performing an action
  };

  // Define the handleMenuClick function to handle clicks on menu items
  const handleMenuClick = (menuItem, index) => {
    console.log('menuitem', menuItem);
    if (menuItem.submenus) {
      handleSubmenuToggle(index);
    } else {
      handleClick(menuItem);
    }
  };

  // Define the handleSubmenuClick function to handle clicks on submenu items
  const handleSubmenuClick = (submenuItem) => {
    // Handle click logic for submenu items
    console.log('Clicked submenu:', submenuItem);
    handelClick(submenuItem);
    // You can add your logic here, such as navigating to a specific link or performing an action
  };

  // Define the handleSubmenuToggle function to toggle submenu visibility
  const handleSubmenuToggle = (index) => {
    if (isOpen(index)) {
      setSubmenuOpen(submenuOpen.filter((i) => i !== index));
    } else {
      setSubmenuOpen([...submenuOpen, index]);
    }
  };

  return (
    <>
      <Modal
        open={openModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Elynker
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to logout?
          </Typography>
          <div style={{ float: 'right' }}>
            <Button
              onClick={() => {
                handleClose();
                resetUserData();
                history.replace('/login');
              }}
            >
              Yes
            </Button>
            <Button onClick={handleClose}>No</Button>
          </div>
        </Box>
      </Modal>
      <Drawer
        variant="permanent"
        className="sidebar-drawer"
        open={open}
        sx={{ background: '#864FFD !important' }}
      >
        <DrawerHeader
          className="justify-content-start"
          style={{ padding: open ? '1.5rem' : '1.5rem 1rem' }}
        >
          {open && <ElynkerLogo />}
          {!open && <ElynkerSmallLogo />}
        </DrawerHeader>

        <List>
          <Typography
            className="sidebar-title"
            style={{ paddingLeft: open ? '25px' : '12px' }}
          >
            Menu
          </Typography>
          {filteredNavigation.map((menuItem, index) => (
            <div key={index}>
              <ListItem
                key={menuItem.title}
                disablePadding
                sx={{ display: 'block' }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: menuItem.submenus
                      ? 'space-between'
                      : open
                      ? 'initial'
                      : 'center',
                    px: 3.5,
                  }}
                  onClick={() => handleMenuClick(menuItem, index)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: menuItem.submenus ? (open ? 3 : 'auto') : 3,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {menuItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={menuItem.title}
                    sx={{ opacity: open ? 1 : 0 }}
                    className="font-manrope fs-18"
                  />
                  {menuItem.submenus && open && (
                    <IconButton
                      sx={{
                        color: open ? '#fff' : '#fff',
                      }}
                      onClick={(event) => handleSubmenuToggle(event, index)}
                    >
                      {isOpen(index) ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  )}
                </ListItemButton>
              </ListItem>

              {menuItem.submenus && open && isOpen(index) && (
                <List className="submenu-list">
                  {menuItem.submenus.map((submenu, subIndex) => (
                    <ListItem key={submenu.title} disablePadding>
                      <ListItemButton
                        sx={{
                          Height: 20,
                          justifyContent: 'center',
                          px: 3,
                          py: '2px',
                        }}
                        onClick={() => handleSubmenuClick(submenu)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 2 : 'auto',
                            justifyContent: 'start',
                            color: 'white',
                          }}
                        >
                          {submenu.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={submenu.title}
                          className="font-manrope fs-18"
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          ))}
        </List>

        <Divider className="sidebar-divider" />
        <List className="mt-4">
          <Typography
            className="sidebar-title"
            style={{ paddingLeft: open ? '25px' : '12px' }}
          >
            Other
          </Typography>

          {filterSettingNavigation.map((menuItem, index) => (
            <div key={index}>
              <ListItem
                key={menuItem.title}
                disablePadding
                sx={{ display: 'block' }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: menuItem.submenus
                      ? 'space-between'
                      : open
                      ? 'initial'
                      : 'center',
                    px: 3.5,
                  }}
                  onClick={() => handleMenuClick(menuItem, index)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: menuItem.submenus ? (open ? 3 : 'auto') : 3,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {menuItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={menuItem.title}
                    sx={{ opacity: open ? 1 : 0 }}
                    className="font-manrope fs-18"
                  />
                  {menuItem.submenus && open && (
                    <IconButton
                      sx={{
                        color: open ? '#fff' : '#fff',
                      }}
                      onClick={(event) => handleSubmenuToggle(event, index)}
                    >
                      {isOpen(index) ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  )}
                </ListItemButton>
              </ListItem>

              {menuItem.submenus && open && isOpen(index) && (
                <List className="submenu-list">
                  {menuItem.submenus.map((submenu, subIndex) => (
                    <ListItem key={submenu.title} disablePadding>
                      <ListItemButton
                        sx={{
                          Height: 20,
                          justifyContent: 'center',
                          px: 3,
                          py: '2px',
                        }}
                        onClick={() => handleSubmenuClick(submenu)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 2 : 'auto',
                            justifyContent: 'start',
                            color: 'white',
                          }}
                        >
                          {submenu.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={submenu.title}
                          className="font-manrope fs-18"
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          ))}

          {['LogOut'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 3.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    if (index === 0) {
                      // handleclearState();
                      setOpenModel(true);
                    }
                  }}
                >
                  {index === 0 ? <LogoutIcon /> : ''}
                </ListItemIcon>
                <ListItemText
                  className="fs-20"
                  primary={text}
                  sx={{ opacity: open ? 1 : 0 }}
                  onClick={() => {
                    if (index === 0) {
                      // handleclearState();
                      setOpenModel(true);
                    }
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
          {open && (
            <ListItem>
              <ListItemText
                primary={`Version-${process.env.REACT_APP_APP_VERSION}`}
                className="fs-8 text-center"
              />
            </ListItem>
          )}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 1, position: 'sticky', background: '#F7F7F7' }}
      >
        <DrawerHeader />
      </Box>
    </>
  );
}

const mapStateToProps = (state) => {
  const { open } = state.root;
  const { userData } = state.signin;
  // console.log("filterNav :", filteredNavigation);
  // console.log("filterSettingnav :", filterSettingNavigation);

  return { open, userData };
};

export default connect(mapStateToProps, { setHeaderTitle, resetUserData })(
  SideList
);
