import { idID } from '@mui/material/locale';
import { DATA_STATUS } from '../utils/constants';
import api from './configs/axiosConfig';

const enquiryMaster = '/enquiry/';
const DOWNLOAD_PDF = '/enquiry-sheet';
const masterStatus = '/moduleDetails/miscellaneous/searchByName/' + DATA_STATUS;
const SEARCH_ENQUIRY = '/enquiry-search';
export const EnquiryListAPI = {
  getEnquiry: async function (page, pageSize) {
    const response = await api.get(
      `/enquiry?page=${page}&pageSize=${pageSize}`
    );
    return response;
  },
  updateEnquiryData: async function (id, data, header) {
    const response = await api.put(enquiryMaster + id, data, header);
    return response;
  },
  getStatusEnquiryData: async function () {
    const response = await api.get(masterStatus);
    return response;
  },
  DownloadPdf: async function (query) {
    const response = await api.get(`${DOWNLOAD_PDF}${query}`);
    return response;
  },
  SearchEnquiry: async function (page, pageSize, search_text) {
    //lucknow?pageSize=10&page=2
    const response = await api.get(`${SEARCH_ENQUIRY}?q=${search_text}`);
    // const response = await api.get(
    //   `${SEARCH_ENQUIRY}?q=${search_text}?page=${page}&pageSize=${pageSize}`
    // );
    return response;
  },
};
